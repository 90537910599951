import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import moment from 'moment';
import { ValidationObserver, ValidationProvider, Validator } from 'vee-validate';
Validator.extend('required', {
    getMessage: field => `${field} deve ser preenchido!`,
    validate: async (value) => !!value
});
let DateFieldComponent = class DateFieldComponent extends Vue {
    constructor() {
        super(...arguments);
        this.date = '';
        this.picker = '';
    }
    onChangePicker(val) {
        if (!val) {
            this.date = '';
            return;
        }
        const m = moment(val, 'YYYY-MM-DD');
        if (!m.isValid()) {
            this.date = '';
            this.picker = '';
            this.$emit('input', null);
            return;
        }
        const formatedPicker = val.split('-');
        const data = formatedPicker[2] + '/' + formatedPicker[1] + '/' + formatedPicker[0];
        this.$emit('input', this.picker);
        if (this.date !== data) {
            this.date = data;
        }
    }
    receiveProp(val) {
        this.picker = val;
    }
    onChangeDate(val) {
        this.$emit('on-change');
        if (val.length === 10) {
            const formatedDate = val.split('/');
            const data = formatedDate[2] + '-' + formatedDate[1] + '-' + formatedDate[0];
            if (this.picker !== data) {
                this.picker = data;
            }
            this.$emit('input', this.picker);
        }
        else {
            this.onChangePicker('');
            this.$emit('input', '');
        }
    }
    mounted() {
        this.picker = this.value;
    }
};
__decorate([
    Prop({ type: String, default: '' })
], DateFieldComponent.prototype, "propTitle", void 0);
__decorate([
    Prop({ type: String, default: null })
], DateFieldComponent.prototype, "propTooltip", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], DateFieldComponent.prototype, "propDisabled", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], DateFieldComponent.prototype, "propDisableValidator", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], DateFieldComponent.prototype, "propReadonly", void 0);
__decorate([
    Prop()
], DateFieldComponent.prototype, "value", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], DateFieldComponent.prototype, "propIcon", void 0);
__decorate([
    Prop({ type: String, default: null })
], DateFieldComponent.prototype, "propAppendIcon", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], DateFieldComponent.prototype, "propDense", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], DateFieldComponent.prototype, "propRequired", void 0);
__decorate([
    Prop({ type: String, default: null })
], DateFieldComponent.prototype, "propCustomClass", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], DateFieldComponent.prototype, "propOutlined", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], DateFieldComponent.prototype, "propRounded", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], DateFieldComponent.prototype, "propAppendCalendar", void 0);
__decorate([
    Watch('picker')
], DateFieldComponent.prototype, "onChangePicker", null);
__decorate([
    Watch('value')
], DateFieldComponent.prototype, "receiveProp", null);
DateFieldComponent = __decorate([
    Component({
        components: {
            ValidationObserver,
            ValidationProvider
        }
    })
], DateFieldComponent);
export default DateFieldComponent;
